import { Link } from 'gatsby'
import React from 'react'
import BlogPostPreviewHorizontal from '../blog-post-preview-horizontal/blog-post-preview-horizontal'

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col
} from "reactstrap";

function BlogPostsPagePreviewGrid (props) {
  return (
    <Container>
      <Row>
        <Col sm={12} className={"ml-auto mr-auto  mt-5 mb-5"}>
        {props.title && (
            <div className={'ml-auto mr-auto text-center'}>
              <h2 className={'title'}>{props.title}</h2>
            </div>
          )}
            { props.nodes &&
              props.nodes.map((node) => (
                <Row className={'mt-5 mb-5'}>
                  <Col sm={12} key={node.id}>
                    <BlogPostPreviewHorizontal  postType={'blog'}  {...node} />
                  </Col>
                </Row>
            ))}
        </Col>
      </Row>
    </Container>
  )
}

BlogPostsPagePreviewGrid.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default BlogPostsPagePreviewGrid
