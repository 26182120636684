import React from 'react'
import { graphql } from 'gatsby'
import { mapEdgesToNodes } from '../lib/helpers'
import BlogPostsPagePreviewGrid from '../components/blog-posts-page-preview-grid/blog-posts-page-preview-grid'
import Container from '../components/container/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import VideoBanner from '../components/video-banner/video-banner'

export const query = graphql`
  query BlogPageQuery {
    page: sanityPage(_id: { regex: "/blogposts/" }) {
          id
        title
        _rawBody
				_rawSubtitle
        useVideo
        pageBranding {
          asset {
            _id
          }
        }
        mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
      }

    posts: allSanityPost(
      limit: 12
      sort: { fields: [publishedAt], order: DESC }
    ) {
      edges {
        node {
          id
          publishedAt
           authors {
            _key
            _type
            person {
              id
              image {
                asset {
                  _id
                }
              }
              name
            }
          }
          mainImage {
            asset {
              _id
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const BlogPage = props => {
  const { data, errors } = props
  const page = (typeof data === "object" && Object.keys(data).length > 0 ? data.page : {}); 
	
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const postNodes = data && data.posts && mapEdgesToNodes(data.posts)

  return (
    <Layout>
      <SEO title='Blog' />
      { page ? <VideoBanner { ...page}/> : <h1 style={{textAlign: 'center', padding: 180, marginTop: 20, backgroundColor: '#1d8cf8'}}>Blog</h1> }
      <Container>
        {postNodes && postNodes.length > 0 && <BlogPostsPagePreviewGrid nodes={postNodes} />}
      </Container>
    </Layout>
  )
}

export default BlogPage
